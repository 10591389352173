<template>
  <div class="widgetContainer replaceCard">
    <div class="loginHeader">
      <img class="loginHeader__logo" :src="logoUrl" alt="logo">
      <div class="loginHeader__footer">
        <p class="cancel" @click="cancel">
          {{ $t('cancel') }}
        </p>
        <p class="description">
          {{ $t('card.text.replaceCard') }}
        </p>
        <p class="submit">
          {{ $t('done') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="replaceCard__details card">
        <ul>
          <li>
            <div class="icon initialsInfo">
              <span class="icon-card" />
            </div>
            <div class="details">
              <p class="label">
                {{ 'VISA *' + this.getSelectedCard.last4 }}
              </p>
              <p class="value">
                Debit card valid until
                {{
                  this.getSelectedCard.expiryMonth +
                    '/' +
                    this.getSelectedCard.expiryYear
                }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="replaceCard__reasons card">
        <p class="sub-header">
          {{ $t('card.text.reason') }}
        </p>
        <ul>
          <li @click="next('replace')">
            <div class="icon initialsInfo">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                {{ $t('card.text.reasonReplace') }}
              </p>
            </div>
            <span class="icon-arrow-right" />
          </li>
          <li @click="next('stolen')">
            <div class="icon initialsInfo">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                {{ $t('card.text.reasonStolen') }}
              </p>
            </div>
            <span class="icon-arrow-right" />
          </li>
          <li @click="next('damege')">
            <div class="icon initialsInfo">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                {{ $t('card.text.reasonDamage') }}
              </p>
            </div>
            <span class="icon-arrow-right" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('card', ['getSelectedCard'])
  },
  methods: {
    ...mapMutations('card', ['updateReplaceCardDate']),
    cancel() {
      this.$router.push('/card/cardManagement');
    },
    next(reason) {
      this.updateReplaceCardDate({
        reason
      });
      this.$router.push('/card/replaceCardDetails');
    }
  }
};
</script>
<style lang="scss" scoped>
.replaceCard {
  .card {
    font-family: 'SF-Pro-Text';
    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
    ul {
      background-color: #fff;
      border-radius: 15px;
      padding: 16px;
      margin-bottom: 30px;
      max-height: 192px;
      overflow-x: hidden;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .icon {
          background-color: var(--branding);
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font-weight: 500;
          width: 40px;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
          }
        }

        .details {
          margin: 0 auto 0 15px;
          padding-right: 15px;
          .label {
            font-size: 15px;
            line-height: 20px;
          }

          .value {
            color: rgba(#3c3c43, 0.6);
            font-size: 12px;
            padding-top: 2px;
          }
        }

        .action {
          cursor: pointer;

          &:hover {
            .icon-arrow-right {
              color: #000;
            }
          }

          .icon-arrow-right {
            padding: 10px;
            color: #c7c7c9;
            transition: 0.3s;
          }

          .details {
            border-radius: 20px;
            background-color: rgba(#5856d6, 0.1);
            color: var(--branding);
            font-size: 13px;
            font-weight: 700;
            padding: 7px 9px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
</style>
